import React from 'react';
import Footer from '../components/Footer';
import HeaderDark from '../components/HeaderDark';
import Session321 from '../components/Session321'
import  "../styles/321global.css"
import  "../styles/launchpad.css"
import  "../styles/circles.css"
import '../styles/nav.css'
import { Helmet } from "react-helmet"
import ProcessGraphic from '../components/ProcessGraphic'
import LPCarrierLogos from '../img/lp-carrier-logos-v2.png'
import Nav from '../components/Nav'
import GetForm from '../components/GetForm';



const LaunchPad = () => {
    return (
        <div id="launchpad_bg">
            <Nav></Nav>
            <div id="launchpad">
                <Helmet>
                    <title>The LaunchPad Quote System: Free Business Insurance Quote</title>
                    <meta name="description" 
                    content="Complete the quote request and an agent will contact you"></meta>
                    <Session321 />
                </Helmet>
                <HeaderDark />
                <div id="quote_page_heading"></div>
                {/* <QuoteForm /> */}
                <GetForm />
                <div id="launchpad_circles">
                    <div className="circle-container">
                        <div id="carrier_logos"><img src={LPCarrierLogos} alt="Insurance321 carrier partner logos" /></div>
                    <div className="circle" id="first_circle">
                        <div className="circle">
                            <div className="circle">
                                <div className="circle">
                                    <div className="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ProcessGraphic />
                <Footer />
            </div>
        </div>
        
     );
}
 
export default LaunchPad;